import Vue from 'vue';
import Web3 from 'web3';
import BigNumber from 'bignumber.js'
import { 
    setTokenContracts, 
    setxTokenContracts, 
    setDevContracts, 
    setFarmContracts, 
    setFarmLibContracts, 
    setFarmPendingABIContracts, 
    setStarterContracts,
    setNodeContracts, 
    setnftContracts, 
    setnftLogicContracts, 
    setnftMarketContracts,
    setMigrateContracts,
    setFactoryContracts,
    setPairContracts,
    transactionSend
} from './setABI.js';

// import WalletConnectProvider from "@walletconnect/web3-provider";
// var provider = new WalletConnectProvider({
//     rpc: {
//         300: "https://rpc.ankr.com/blast_testnet_sepolia"
//     },
//     "chainId": 300
// })
// export async function serWCP(){
//     await provider.enable();
//     return provider
// };

// import { Core } from '@walletconnect/core'
// import { Web3Wallet } from '@walletconnect/web3wallet'
// //console.log(Core);
if(typeof window.ethereum !== 'undefined'){
    window.ethereum.on('chainChanged', function (networkIDstring) {
        //console.log(networkIDstring)
        localStorage.setItem('walletNetwork',networkIDstring)
        window.location.reload();
    })
    window.ethereum.request({method:'net_version'}).then(res => {
        localStorage.setItem('walletNetwork',Web3.utils.toHex(res))
    })
    window.ethereum.on('accountsChanged', function (accounts) {
        if(accounts.length == 0){
            localStorage.removeItem('userAddress')
            Vue.prototype.$message.warning('Switch accounts, refreshing');
            window.location.reload();
        } else {
            localStorage.setItem('userAddress', accounts[0])
        }
        window.ethereum.request({method:'net_version'}).then(res => {
            if (Web3.utils.toHex(res) == localStorage.getItem('chainId')) {
                Vue.prototype.$message.warning('Switch accounts, refreshing');
                window.location.reload();
            }
        })
    })
}



export function judgeNetwork () {
    return new Promise((resolve,reject) => {
        if(localStorage.getItem('walletType') == 'OKXWALLET'){
            window.bitkeep.ethereum.request({method:'net_version'}).then(res => {
                //console.log(res,Web3.utils.toHex(res),localStorage.getItem('chainId'))
                if (Web3.utils.toHex(res) != localStorage.getItem('chainId')) {
                    resolve(0)
                    window.bitkeep.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            'chainId': localStorage.getItem('chainId'),
                            'chainName': localStorage.getItem('chainName'),
                            'nativeCurrency': {
                                'name': localStorage.getItem('nativeCurrencyName'),
                                'symbol': localStorage.getItem('nativeCurrencySymbol'),
                                'decimals':18,
                            },
                            'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                            'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                        }],
                    }).then((success) => {
                    },error => {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    }).catch(function(err) {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    })
                } else {
                    resolve(1)
                    
                }
            });
        }else if(localStorage.getItem('walletType') == 'BITGETWALLET'){
            window.okxwallet.request({method:'net_version'}).then(res => {
                //console.log(res,Web3.utils.toHex(res),localStorage.getItem('chainId'))
                if (Web3.utils.toHex(res) != localStorage.getItem('chainId')) {
                    resolve(0)
                    window.okxwallet.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            'chainId': localStorage.getItem('chainId'),
                            'chainName': localStorage.getItem('chainName'),
                            'nativeCurrency': {
                                'name': localStorage.getItem('nativeCurrencyName'),
                                'symbol': localStorage.getItem('nativeCurrencySymbol'),
                                'decimals':18,
                            },
                            'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                            'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                        }],
                    }).then((success) => {
                    },error => {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    }).catch(function(err) {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    })
                } else {
                    resolve(1)
                    
                }
            });
        }else{
            window.ethereum.request({method:'net_version'}).then(res => {
                //console.log(res,Web3.utils.toHex(res),localStorage.getItem('chainId'))
                if (Web3.utils.toHex(res) != localStorage.getItem('chainId')) {
                    resolve(0)
                    window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            'chainId': localStorage.getItem('chainId'),
                            'chainName': localStorage.getItem('chainName'),
                            'nativeCurrency': {
                                'name': localStorage.getItem('nativeCurrencyName'),
                                'symbol': localStorage.getItem('nativeCurrencySymbol'),
                                'decimals':18,
                            },
                            'rpcUrls': JSON.parse(localStorage.getItem('rpcUrls')),
                            'blockExplorerUrls': JSON.parse(localStorage.getItem('blockExplorerUrls')),
                        }],
                    }).then((success) => {
                    },error => {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    }).catch(function(err) {
                        Vue.prototype.$notify({type:'warning',message:'Please connect wallet first!'})
                    })
                } else {
                    resolve(1)
                    
                }
            }).catch(error => {
                //console.log(error)
            });
        }
    })
}

//token-----star
export function getraiseSupply(contractAddr) {
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.raiseSupply().call().then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    });
};
export function getTokenSymbol(contractAddr) {
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.symbol().call().then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    });
};
export function getTokenDecimals(contractAddr) {
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.decimals().call().then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    });
};
export function getxTokenSymbol(contractAddr) {
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.symbol().call().then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    });
};
getTokenSymbol(window.contractAddr.tokenAddress).then(symbol => {
    localStorage.setItem('tokenSymbol',symbol)
})
getxTokenSymbol(window.contractAddr.xTokenAddress).then(xsymbol => {
    localStorage.setItem('xtokenSymbol',xsymbol)
})
export function getBalanceOf(contractAddr,from) {
    if(!from){var from = ''}
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            if (contractAddr == '0x0000000000000000000000000000000000000000') {
                window.ethereum.request({
                    method: 'eth_getBalance',
                    params: [from],
                }).then((success) => {
                    resolve(Number(Web3.utils.toBN(success).toString()))
                })
            } else {
                contract.methods.balanceOf(from).call()
                .then(res => {
                    resolve(res)
                }).catch(error =>{
                    reject(error)
                });
            }
        });
    });
};
export function burnNumber(contractAddr,){
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.burnNumber().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function gettotalSupply(contractAddr){
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.totalSupply().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getAllowance(contractAddr,offeringCAddr,from){
    return new setTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.allowance(from,offeringCAddr).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}

export function setApprove(contractAddr,offeringCAddr,from) {
    if(!from){var from = ''}
    return new setTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.approve(offeringCAddr,'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setNFTApprove(contractAddr,offeringCAddr,id,from) {
    if(!from){var from = ''}
    return new setTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.approve(offeringCAddr,id)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};

export function getxstarBalanceof(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.balanceOf(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getfarmStakingBalancOf(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.farmStakingBalancOf(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getunbondingBalancOf(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.unbondingBalancOf(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getminRedeemDuration(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.minRedeemDuration().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getmaxRedeemDuration(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.maxRedeemDuration().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getminRedeemRatio(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.minRedeemRatio().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getmaxRedeemRatio(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.maxRedeemRatio().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getStarByVestingDuration(contractAddr,amount,_class,from){
    let amount_ = Web3.utils.toBN(amount)
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getStarByVestingDuration(amount_,_class).call({from:from})
            .then(res => {
                //console.log(res)
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getUserRedeemsLength(contractAddr,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getUserRedeemsLength(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getuserRedeems(contractAddr,index,from){
    return new setxTokenContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userRedeems(from,index).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function setconvert(contractAddr,amount,from) {
    if(!from){var from = ''}
    let amount_ = Web3.utils.toBN(amount)
    return new setxTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.convert(amount_)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setredeem(contractAddr,amount,time,from) {
    if(!from){var from = ''}
    let amount_ = Web3.utils.toBN(amount)
    return new setxTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.redeem(amount_,time)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setfinalizeRedeem(contractAddr,index,from) {
    if(!from){var from = ''}
    return new setxTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.finalizeRedeem(index)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setcancelRedeem(contractAddr,index,from) {
    if(!from){var from = ''}
    return new setxTokenContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.cancelRedeem(index)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//token-----end

//farm-----star
export function getuserInfo (contractAddr,pid,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userInfo(pid,from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
};
export function getRedeens (contractAddr,pid,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getRedeens(pid).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function handdleFarmDeposit(contractAddr,pid,amount,from) {
    //console.log(contractAddr,pid,amount,from)
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.deposit(pid,amount)).then(res => {
                resolve(res)
            }).catch(error => {
                //console.log(error)
                reject(error)
            })
        });
    })
};
export function handdleFarmWithdraw(contractAddr,pid,amount,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.withdraw(pid,amount)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function addRedeens(contractAddr,pid,amount,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.addRedeens(pid,amount)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function withdrawRedeens(contractAddr,pid,index,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.withdrawRedeens(pid,index)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function delRedeens(contractAddr,pid,index,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.delRedeens(pid,index)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function getStakingNFTAmount (contractAddr,) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getStakingNFTAmount().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function StakingNFTs (contractAddr,index) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.StakingNFTs(index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function getNFTGroupAmount (contractAddr,level,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getNFTGroupAmount(level).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function NFTGroup (contractAddr,level,index,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.NFTGroup(level,index).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function getUserStakingNFTAmount (contractAddr,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getUserStakingNFTAmount(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function userNFTs (contractAddr,index,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userNFTs(from,index).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function nftUser (contractAddr,NFTId,from) {
    return new setFarmContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nftUser(NFTId).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
};
export function enterStakingNFT(contractAddr,id,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.enterStakingNFT(id)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function leaveStakingNFT(contractAddr,id,from) {
    if(!from){var from = ''}
    return new setFarmContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.leaveStakingNFT(id)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//farm-----end

//farmLib-----star
export function getstarPerBlock (contractAddr,from) {
    return new setFarmLibContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.starPerBlock().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
};
export function gettotalAllocPoint(contractAddr ,from){
    return new setFarmLibContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.totalAllocPoint().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getpoolInfo(contractAddr,pid,from){
    return new setFarmLibContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.poolInfo(pid).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getpoolLpInfo(contractAddr,pid,from){
    return new setFarmLibContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.poolLpInfo(pid).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getboost(contractAddr,pid,from){
    return new setFarmLibContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.boost(pid).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
//farmLib-----end

//farmPending-----star
export function getPendingStar(contractAddr,pid,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.pendingStar(pid,from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getuserBlp(contractAddr,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userBlp(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getMultiplier(contractAddr,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getMultiplier(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getMinMultiplier(contractAddr,pid,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getMinMultiplier(pid,from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getXMultiplier(contractAddr,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getXMultiplier(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function nftPendingStar(contractAddr,id,from){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nftPendingStar(from,id).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function nftBoost(contractAddr){
    return new setFarmPendingABIContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nftBoost().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
//farmPending-----end
//starter-----star
export function setTledUnderlyingOf(poolItem,addr,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.settledUnderlyingOf(addr).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getTotalDeposit(poolItem,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        if (poolItem.accessType == 0) {
            return new Promise((resolve, reject) => {
                contract.methods.totalPurchasedCurrency().call()
                .then(res => {
                    resolve(res)
                }).catch(error =>{
                    reject(error)
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                contract.methods.totalPurchasedUnderlying().call()
                .then(res => {
                    resolve(res)
                }).catch(error =>{
                    reject(error)
                });
            });
        }
    })
}
export function getDepositOf(poolItem,addr,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        if (poolItem.accessType == 0) {
            return new Promise((resolve, reject) => {
                contract.methods.purchasedCurrencyOf(addr).call()
                .then(res => {
                    resolve(res)
                }).catch(error =>{
                    reject(error)
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                contract.methods.purchasedUnderlyingOf(addr).call()
                .then(res => {
                    resolve(res)
                }).catch(error =>{
                    reject(error)
                });
            });
        }
    })
}
export function getReceiveRatio(poolItem,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.receiveRatio(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getReceiveNumber(poolItem,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.receiveNumber(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getRatioBNB(poolItem,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', 0).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.ratioBNB().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getQuotaOf(poolItem,addr,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.quotaOf(addr).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getIntervalTime(poolItem,num,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.intervalTime(num).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getIntervalNumber(poolItem,from){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.intervalNumber().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getHarvestable(poolItem,addr,boolfrom){
    return new setStarterContracts(poolItem.offerContract, 'call', '', poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.settleable(addr).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}

export function setHarvest (poolItem,from) {
    if(!from){var from = ''}
    return new setStarterContracts(poolItem.offerContract, 'send','',poolItem.accessType).then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,poolItem.offerContract,contract.methods.settle()).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setDeposit (poolItem,amount,from,Payment) {
    //console.log(poolItem,amount,from,Payment)
    if(!from){var from = ''}
    return new setStarterContracts(poolItem.offerContract, 'send','',poolItem.accessType).then( contract => {
        if(Payment == 1){
            //console.log(amount.toString())
            return new Promise((resolve, reject) => {
                transactionSend(from,poolItem.offerContract,contract.methods.purchase(amount)).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        }else{
            //console.log(amount.toString())
            return new Promise((resolve, reject) => {
                transactionSend(from,poolItem.offerContract,contract.methods.purchaseBNB(),amount).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        }
    })
};
//starter-----end
//node-----end
export function getNodesLength(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeLength().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function userNumber(contractAddr){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userNumber().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodeAward(contractAddr){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.award().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodexAward(contractAddr){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.xaward().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function awardNodeUsers(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.awardNodeUsers(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodeInfo(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeInfo(from).call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function leastUnit(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.leastUnit().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function unitPrice(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.unitPrice().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function xunitPrice(contractAddr,from){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.xunitPrice().call({from:from})
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getnameUsersLength(contractAddr,serach){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nameUsersLength(serach).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getnameUsers(contractAddr,serach,index){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nameUsers(serach,index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getSearchNode(contractAddr,address){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getNode(address).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodes(contractAddr,nid){
    var _nid = Web3.utils.toBN( new BigNumber(nid) )
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodes(_nid).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function nodeNotes(contractAddr,nid){
    var _nid = Web3.utils.toBN( new BigNumber(nid) )
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeNotes(_nid).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function nodeFeeType(contractAddr,nid){
    var _nid = Web3.utils.toBN( new BigNumber(nid) )
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeFeeType(_nid).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function nodeXfee(contractAddr,nid){
    var _nid = Web3.utils.toBN( new BigNumber(nid) )
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeXfee(_nid).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodeUserLength(contractAddr,owner){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeUserLength(owner).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodeUsers(contractAddr,owner){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getNodeUsers(owner).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getUserInviter(contractAddr,address){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userInviter(address).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function getNodeLpLength(contractAddr){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getNodeLpLength().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function nodeLpArr(contractAddr,index){
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeLpArr(index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function nodeLp(contractAddr,nid,tokenAddress){
    var _nid = Web3.utils.toBN( new BigNumber(nid) )
    return new setNodeContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.nodeLp(_nid,tokenAddress).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}

export function handdleNodeWithdraw (contractAddr,from) {
    if(!from){var from = ''}
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.withdraw()).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function regFromNode (contractAddr,address,userxfee,bytes4,from) {
    if(!from){var from = ''}
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.regFromNode(address,userxfee,bytes4)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function depositNode (contractAddr,_unit,_usexToken,_nodeFeeType,joinPrice,joinxPrice,_name,_social,_notes,from) {
    if(!from){var from = ''}
    var _fee = Web3.utils.toBN( new BigNumber(joinPrice * Math.pow(10,18)) )
    var _xfee = Web3.utils.toBN( new BigNumber(joinxPrice * Math.pow(10,18)) )
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            //console.log(_unit,_usexToken,_nodeFeeType,_fee,_xfee,_name,_social,_notes)
            transactionSend(from,contractAddr,contract.methods.depositNode(_unit,_usexToken,_nodeFeeType,_fee,_xfee,_name,_social,_notes)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setName (contractAddr,name,from) {
    if(!from){var from = ''}
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.setName(name)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setNotes (contractAddr,name,from) {
    if(!from){var from = ''}
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.setNotes(name)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setnodeJoiningFee (contractAddr,_nodeFeeType,joinPrice,joinxPrice,from) {
    if(!from){var from = ''}
    var _fee = Web3.utils.toBN( new BigNumber(joinPrice * Math.pow(10,18)) )
    var _xfee = Web3.utils.toBN( new BigNumber(joinxPrice * Math.pow(10,18)) )
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.setJoiningFee(_nodeFeeType,_fee,_xfee)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function setSocial (contractAddr,social,from) {
    if(!from){var from = ''}
    return new setNodeContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.setSocial(social)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//node-----end

//nft-----star
export function getNFTTotalSupply(contractAddr){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.totalSupply().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function ownerOf(contractAddr,id,from){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.ownerOf(id).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function nftBalanceOf(contractAddr,from){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.balanceOf(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function tokenOfOwnerByIndex(contractAddr,index,from){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.tokenOfOwnerByIndex(from,index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function cateURI(contractAddr,index){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.cateURI(index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getApproved(contractAddr,id){
    return new setnftContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getApproved(id).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
//nft-----end

//nftLogic-----star
export function levelMethod(contractAddr,level){
    var _level = Web3.utils.toBN( new BigNumber(level) )
    return new setnftLogicContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.levelMethod(_level).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function Xprice(contractAddr){
    return new setnftLogicContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.Xprice().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function starMeta(contractAddr,index){
    return new setnftLogicContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.starMeta(index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
    
        });
    })
}
export function mint (contractAddr,mintToken,from) {
    if(!from){var from = ''}
    return new setnftLogicContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,mintToken == 0 ? contract.methods.mint() : contract.methods.Xmint()).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function melt (contractAddr,arr,level,from) {
    if(!from){var from = ''}
    if (level == 1) { var [a,b] = [0,1] } else if (level == 2) { var [a,b] = [1,2] }
    return new setnftLogicContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.melt(arr,a,b)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//nftLogic-----end

//nftMarket-----star
export function getGroupTokensLength(contractAddr,level,from){
    return new setnftMarketContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getGroupTokensLength(level).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function groupTokens(contractAddr,level,index,from){
    return new setnftMarketContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.groupTokens(level,index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function marketTokenInfo(contractAddr,tokenid,from){
    return new setnftMarketContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.tokenInfo(tokenid).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getUserTokensLength(contractAddr,from){
    return new setnftMarketContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getUserTokensLength(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function userTokens(contractAddr,i,from){
    return new setnftMarketContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userTokens(from,i).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function setTokenSale(contractAddr,id,sale,price,from) {
    if(!from){var from = ''}
    var _price = Web3.utils.toBN( new BigNumber(price * Math.pow(10,18)) )
    return new setnftMarketContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.setTokenSale(id,sale,_price)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function purchaseToken(contractAddr,id,from) {
    if(!from){var from = ''}
    return new setnftMarketContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.purchaseToken(id)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//nftMarket-----end

//setDevContracts-----starsetMigrateContracts
export function totalAmount(contractAddr){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.totalAmount().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function XtotalAmount(contractAddr){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.XtotalAmount().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getNFTUserInfo(contractAddr,from){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userInfo(from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getBonusInfo(contractAddr,token){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.bonusInfo(token).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getUserReward(contractAddr,token,from){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.getUserReward(token,from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function userRewardInfo(contractAddr,token,from){
    return new setDevContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.userRewardInfo(token,from).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function withdrawBool(contractAddr,nftBool,blpBool,xspaceBool,from) {
    if(!from){var from = ''}
    return new setDevContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.withdraw(nftBool,blpBool,xspaceBool)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//setDevContracts-----end

//setMigrateContracts-----star
export function MigrateNFT(contractAddr,NFTIds,from) {
    if(!from){var from = ''}
    return new setMigrateContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.MigrateNFT(NFTIds)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function MigrateStarToken(contractAddr,amount,from) {
    if(!from){var from = ''}
    return new setMigrateContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.MigrateStarToken(amount)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
export function MigrateXStarToken(contractAddr,amount,from) {
    if(!from){var from = ''}
    return new setMigrateContracts(contractAddr, 'send').then( contract => {
        return new Promise((resolve, reject) => {
            transactionSend(from,contractAddr,contract.methods.MigrateXStarToken(amount)).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    })
};
//setMigrateContracts-----end

//setFactoryContracts-----star
export function getAllPairsLength(contractAddr){
    return new setFactoryContracts(contractAddr, 'call').then( contract => {
        console.log(contract)
        return new Promise((resolve, reject) => {
            contract.methods.allPairsLength().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getAllPairs(contractAddr,index){
    return new setFactoryContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.allPairs(index).call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
//setFactoryContracts-----end

//setPairContracts-----star
export function getPairToken0(contractAddr){
    return new setPairContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.token0().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
export function getPairToken1(contractAddr){
    return new setPairContracts(contractAddr, 'call').then( contract => {
        return new Promise((resolve, reject) => {
            contract.methods.token1().call()
            .then(res => {
                resolve(res)
            }).catch(error =>{
                reject(error)
            });
        });
    })
}
//setPairContracts-----end




 



//farm star
// function setFarmDeposit(contractAddr,from) {
//     if(!from){var from = ''}
//     return new setFarmContracts(contractAddr, 'send').then( contract => {
//         return new Promise((resolve, reject) => {
//             transactionSend(from,contractAddr,contract.methods.setDeposit('true')).then(res => {
//                 resolve(res)
//             }).catch(error => {
//                 reject(error)
//             })
//         });
//     })
// }; 
// setFarmDeposit('0x0f086f9724A71B78b42c481B4194860E5c925066',localStorage.getItem('userAddress'))
//farm star