import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/Home'
	},
	{
		path: '/Home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
	},{
		path: '/Pool',
		name: 'Pool',
		component: () => import(/* webpackChunkName: "Pool" */ '../views/Pool.vue')
	},
	// {
	// 	path: '/Farm',
	// 	name: 'Farm',
	// 	component: () => import(/* webpackChunkName: "Farm" */ '../views/Farm.vue')
	// },{
	// 	path: '/Mint',
	// 	name: 'Mint',
	// 	component: () => import(/* webpackChunkName: "Mint" */ '../views/Mint.vue')
	// },{
	// 	path: '/Staking',
	// 	name: 'Staking',
	// 	component: () => import(/* webpackChunkName: "Staking" */ '../views/Staking.vue')
	// },{
	// 	path: '/Marketplace',
	// 	name: 'Marketplace',
	// 	component: () => import(/* webpackChunkName: "Marketplace" */ '../views/Marketplace.vue')
	// },{
	// 	path: '/MyNFT',
	// 	name: 'MyNFT',
	// 	component: () => import(/* webpackChunkName: "MyNFT" */ '../views/MyNFT.vue')
	// },{
	// 	path: '/nftDetail',
	// 	name: 'nftDetail',
	// 	component: () => import(/* webpackChunkName: "nftDetail" */ '../views/nftDetail.vue')
	// },{
	// 	path: '/Spacebase',
	// 	name: 'Spacebase',
	// 	component: () => import(/* webpackChunkName: "Spacebase" */ '../views/Spacebase.vue')
	// },{
	// 	path: '/xSPACE',
	// 	name: 'xSPACE',
	// 	component: () => import(/* webpackChunkName: "xSPACE" */ '../views/xSPACE.vue')
	// },
	{
		path: '/Launchpad',
		name: 'Launchpad',
		component: () => import(/* webpackChunkName: "Starter" */ '../views/Starter.vue')
	},
	// {
	// 	path: '/LaunchpadDetail',
	// 	name: 'LaunchpadDetail',
	// 	component: () => import(/* webpackChunkName: "StarterDetail" */ '../views/StarterDetail.vue')
	// },
	{
		path: '/Bridge',
		name: 'Bridge',
		component: () => import(/* webpackChunkName: "Bridge" */ '../views/Bridge.vue')
	},
	// {
	// 	path: '/Points',
	// 	name: 'Points',
	// 	component: () => import(/* webpackChunkName: "Points" */ '../views/Points.vue')
	// },{
	// 	path: '/PointsStore',
	// 	name: 'PointsStore',
	// 	component: () => import(/* webpackChunkName: "PointsStore" */ '../views/PointsStore.vue')
	// },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0}
	}
})

export default router
