import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.timeout = 10*60*1000
axios.interceptors.request.use(function (config) {
	let _a = config.url.split('/')[1]
	if(_a == 'token'){
		axios.defaults.timeout = 500
	}
	const token = Cookies.get('token');
	config.headers['token'] = token;
	return config
}, function (error) {
	return Promise.reject(error)
});
axios.interceptors.response.use(function (res) {
	if(res.data.pair){
		return res.data
	}
	if(res.data.pairs){
		return res.data
	}
	if(!res.error) {
		return res.data.data
	} else {
		return {
		price:1,
		}
	}
}, function (error) {
	return {
		price:1,
	}
});

export default {
    post: function (url,params) {
		return axios({
			method: 'post',
			url,
			params: qs.parse(params),
			timeout: 1000*60*10,
			withCredentials: false,
		})
    },
    get: function (url,params) {
		return axios({
			method: 'get',
			url,
			params: qs.parse(params),
			timeout: 1000*60*10,
			withCredentials: false,
		})
    },
    file: function (url,data,config) {
		return axios({
			method: 'post',
			url: url,
			data: data,
			config: config,
			withCredentials: false,
		})
    }
}


